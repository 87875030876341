// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  TIMESHEET_LIST_REQUEST,
  TIMESHEET_LIST_SUCCESS,
  TIMESHEET_LIST_FAIL,
  TIMESHEET_LIST_RESET,
  TIMESHEET_CREATE_REQUEST,
  TIMESHEET_CREATE_SUCCESS,
  TIMESHEET_CREATE_FAIL,
  TIMESHEET_CREATE_RESET,
  TIMESHEET_DETAILS_REQUEST,
  TIMESHEET_DETAILS_SUCCESS,
  TIMESHEET_DETAILS_FAIL,
  TIMESHEET_DETAILS_RESET,
  TIMESHEET_UPDATE_REQUEST,
  TIMESHEET_UPDATE_SUCCESS,
  TIMESHEET_UPDATE_FAIL,
  TIMESHEET_UPDATE_RESET,
  TIMESHEET_DELETE_REQUEST,
  TIMESHEET_DELETE_SUCCESS,
  TIMESHEET_DELETE_FAIL,
  TIMESHEET_DELETE_RESET,
  TIMESHEET_DOWNLOAD_REQUEST,
  TIMESHEET_DOWNLOAD_SUCCESS,
  TIMESHEET_DOWNLOAD_FAIL,
  TIMESHEET_DOWNLOAD_RESET,
  TIMESHEET_DUPLICATE_REQUEST,
  TIMESHEET_DUPLICATE_SUCCESS,
  TIMESHEET_DUPLICATE_FAIL,
  TIMESHEET_DUPLICATE_RESET,
  TIMESHEET_REVERSE_REQUEST,
  TIMESHEET_REVERSE_SUCCESS,
  TIMESHEET_REVERSE_FAIL,
  TIMESHEET_REVERSE_RESET,
} from "../constants/timesheetConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listTimesheetsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIMESHEET_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getTimesheets(params, token);

    dispatch({
      type: TIMESHEET_LIST_SUCCESS,

      payload: {
        count: data.count,
        timesheets: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: TIMESHEET_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListTimesheetsAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_LIST_RESET });
};

// Create
export const createTimesheetAction =
  (timesheet) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createTimesheet(
        token,
        timesheet
      );

      dispatch({
        type: TIMESHEET_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateTimesheetAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_CREATE_RESET });
};

// List details
export const listTimesheetDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getTimesheet(id, token);

      dispatch({
        type: TIMESHEET_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListTimesheetDetailsAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_DETAILS_RESET });
};

// Update
export const updateTimesheetAction =
  (id, approvalRequest, timesheet) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateTimesheet(
        id,
        token,
        timesheet
      );

      if (approvalRequest) {
        if (approvalRequest.get("approver")) {
          await cortixDataService.createApprovalRequest(token, approvalRequest);
        }
      }

      dispatch({
        type: TIMESHEET_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateTimesheetAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_UPDATE_RESET });
};

// Delete
export const deleteTimesheetAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIMESHEET_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteTimesheet(id, token);

    dispatch({
      type: TIMESHEET_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TIMESHEET_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteTimesheetAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_DELETE_RESET });
};

// Download
export const downloadTimesheetsAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var timesheets = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getTimesheets(
          { organisation: organisation, page: page, range: 26 },
          token
        );

        timesheets = timesheets.concat(data.results);
      }

      dispatch({
        type: TIMESHEET_DOWNLOAD_SUCCESS,

        payload: {
          timesheets: timesheets,
        },
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadTimesheetsAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_DOWNLOAD_RESET });
};

// Duplicate
export const duplicateTimesheetAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIMESHEET_DUPLICATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.duplicateTimesheet(id, token);

    dispatch({
      type: TIMESHEET_DUPLICATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TIMESHEET_DUPLICATE_FAIL,

      payload: error,
    });
  }
};

// Reset duplicate
export const resetDuplicateTimesheetAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_DUPLICATE_RESET });
};

// Reverse
export const reverseTimesheetAction =
  (id, reversal) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_REVERSE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.reverseTimesheet(
        id,
        token,
        reversal
      );

      dispatch({
        type: TIMESHEET_REVERSE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_REVERSE_FAIL,

        payload: error,
      });
    }
  };

// Reset reverse
export const resetReverseTimesheetAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_REVERSE_RESET });
};
