// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import { Button, Colors, Tag } from "@blueprintjs/core";
import ExcelJS from "exceljs/dist/exceljs";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import InfoPopup from "../components/InfoPopup";
import NavbarContainer from "../components/NavbarContainer";
import StaffBookingsCard2 from "../components/StaffBookingsCard2";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableText from "../components/TableText";

// Redux action imports
// Location
import { listLocationsAction } from "../actions/locationActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Role
import { listRolesAction } from "../actions/roleActions";

// Staff
import {
  listStaffAction,
  resetListStaffDetailsAction,
} from "../actions/staffActions";

// Timesheet
import {
  downloadTimesheetsAction,
  listTimesheetsAction,
  resetDownloadTimesheetsAction,
} from "../actions/timesheetActions";

// Main function
function StaffListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Location
  const locationList = useSelector((state) => state.locationList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Role
  const roleList = useSelector((state) => state.roleList);

  // Staff
  const staffList = useSelector((state) => state.staffList);

  // Timesheet
  const timesheetDownload = useSelector((state) => state.timesheetDownload);

  const timesheetList = useSelector((state) => state.timesheetList);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Search and filter variables
  // Staff
  const staffQueryDefault = {
    bookingProfile: "month",
    competencies: "",
    employment: "",
    freeTime: "",
    level: "month",
    location: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
    staff: "",
    utilisationScore: "",
  };
  const [staffQuery, setStaffQuery] = useState({
    ...staffQueryDefault,
  });

  // Location
  const locationQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [locationQuery, setLocationQuery] = useState({
    ...locationQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Role
  const roleQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [roleQuery, setRoleQuery] = useState({
    ...roleQueryDefault,
  });

  // Timesheet
  const timesheetQueryDefault = {
    completedEnd: "",
    completedStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    range: 26,
    staff: "",
    status: "",
    submittedEnd: "",
    submittedStart: "",
    user: "",
    weekLabel: "",
  };
  const [timesheetQuery] = useState({
    ...timesheetQueryDefault,
  });

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter locations
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listLocationsAction({ ...locationQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [locationQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter roles
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRolesAction({
            ...roleQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [roleQuery]);

  // Filter staff
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listStaffAction({ ...staffQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffQuery]);

  // Filter timesheets
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listTimesheetsAction({
            ...timesheetQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [timesheetQuery]);

  // Form submission
  // Timesheet
  // Download timesheets
  const downloadTimesheets = useCallback(async () => {
    // Create workbook
    const timesheetWorkbook = new ExcelJS.Workbook();

    timesheetWorkbook.created = new Date();

    timesheetWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = timesheetWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Timesheets",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      ["1. This file provides timesheet data covering the previous 26 weeks."],
      ["2. The tab 'Timesheets' contains the timesheet data."],
      ["3. Field definitions for timesheets:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Staff: The team member who booked time."],
      ["(c) Week: The week of the timesheet."],
      ["(d) Mon: The time booked on Monday in minutes."],
      ["(e) Tue: The time booked on Tuesday in minutes."],
      ["(f) Wed: The time booked on Wednesday in minutes."],
      ["(g) Thu: The time booked on Thursday in minutes."],
      ["(h) Fri: The time booked on Friday in minutes."],
      ["(i) Sat: The time booked on Saturday in minutes."],
      ["(j) Sun: The time booked on Sunday in minutes."],
      ["(k) Total: The total time booked in minutes."],
      ["(l) Comments: Additional information on the timesheet, if available."],
      [
        "(m) Status: The status of the timesheet, either 'Approved', 'Draft', 'Rejected', 'Reversed' or 'Submitted'.",
      ],
      ["4. The tab 'Entries' contains detailed timesheet entries."],
      ["5. Field definitions for entries:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Booking Code: The code that time was booked against."],
      ["(c) Booking Code Name: A short description of the booking code."],
      [
        "(d) Billable: Whether the booking code is billable, either 'Yes' or 'No'.",
      ],
      ["(e) Task: The task associated with the entry, if any."],
      [
        "(f) Engagement Role: The engagement role associated with the entry, if any.",
      ],
      [
        "(g) Leave category code: A code mapping the type of leave taken to the corresponding payroll system category (if relevant).",
      ],
      ["(h) Leave category name: The type of leave taken (if relevant)."],
      ["(i) Mon: The time booked on Monday in minutes."],
      ["(j) Tue: The time booked on Tuesday in minutes."],
      ["(k) Wed: The time booked on Wednesday in minutes."],
      ["(l) Thu: The time booked on Thursday in minutes."],
      ["(m) Fri: The time booked on Friday in minutes."],
      ["(n) Sat: The time booked on Saturday in minutes."],
      ["(o) Sun: The time booked on Sunday in minutes."],
      ["(p) Total: The total time booked in minutes."],
      ["(q) Comments: Additional information on the entry, if available."],
      [
        "(r) Timesheet: A unique identifier, which links the entry to a timesheet in the Timesheets tab.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create timesheets data sheet
    const timesheetSheet = timesheetWorkbook.addWorksheet("Timesheets", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${activeOrganisation.name} - Timesheets`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style timesheets data sheet columns
    timesheetSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Staff",
        key: "staff",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Week",
        key: "week",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Mon",
        key: "mon",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Tue",
        key: "tue",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Wed",
        key: "wed",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Thu",
        key: "thu",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Fri",
        key: "fri",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Sat",
        key: "sat",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Sun",
        key: "sun",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Total",
        key: "total",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Comments",
        key: "comments",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Status",
        key: "status",
        style: columnStyle,
        width: 20,
      },
    ];

    timesheetSheet.autoFilter = "A1:M1";

    const timesheetsTopRow = timesheetSheet.getRow(1);
    timesheetsTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    timesheetSheet.addConditionalFormatting({
      ref: "M2:M20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Approved"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Draft"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "8ABBFF" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Rejected"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Reversed"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Submitted"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    timesheetSheet.pageSetup.printTitlesRow = "1:13";

    // Populate timesheets data sheet data
    for (var i = 0; i < timesheetDownload.timesheets.length; i++) {
      timesheetSheet.addRow({
        id: timesheetDownload.timesheets[i].id,
        staff: timesheetDownload.timesheets[i].staff_name,
        week: timesheetDownload.timesheets[i].week_label,
        mon: timesheetDownload.timesheets[i].total_minutes_mon,
        tue: timesheetDownload.timesheets[i].total_minutes_tue,
        wed: timesheetDownload.timesheets[i].total_minutes_wed,
        thu: timesheetDownload.timesheets[i].total_minutes_thu,
        fri: timesheetDownload.timesheets[i].total_minutes_fri,
        sat: timesheetDownload.timesheets[i].total_minutes_sat,
        sun: timesheetDownload.timesheets[i].total_minutes_sun,
        total: timesheetDownload.timesheets[i].total_minutes,
        comments: timesheetDownload.timesheets[i].comments
          ? timesheetDownload.timesheets[i].comments
          : "",
        status: timesheetDownload.timesheets[i].status,
      });
    }

    // Protect timesheets data sheet
    timesheetSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create entries data sheet
    const entriesSheet = timesheetWorkbook.addWorksheet("Entries", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${activeOrganisation.name} - Timesheet Entries`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style entries data sheet columns
    entriesSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Booking Code",
        key: "bookingCode",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Booking Code Name",
        key: "bookingCodeName",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Billable",
        key: "billable",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Task",
        key: "task",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Engagement Role",
        key: "engagementRole",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Leave Category Code",
        key: "leaveCategoryCode",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Leave Category Name",
        key: "leaveCategoryName",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Mon",
        key: "mon",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Tue",
        key: "tue",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Wed",
        key: "wed",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Thu",
        key: "thu",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Fri",
        key: "fri",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Sat",
        key: "sat",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Sun",
        key: "sun",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Total",
        key: "total",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Comments",
        key: "comments",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Timesheet",
        key: "timesheet",
        style: columnStyle,
        width: 20,
      },
    ];

    entriesSheet.autoFilter = "A1:P1";

    const entriesTopRow = entriesSheet.getRow(1);
    entriesTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    entriesSheet.addConditionalFormatting({
      ref: "D2:D20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"No"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    entriesSheet.pageSetup.printTitlesRow = "1:16";

    // Populate entries data sheet data
    for (var j = 0; j < timesheetDownload.timesheets.length; j++) {
      let entries = timesheetDownload.timesheets[j].entries;

      for (var k = 0; k < entries.length; k++) {
        entriesSheet.addRow({
          id: entries[k].id,
          bookingCode: entries[k].booking_code_data.code,
          bookingCodeName: entries[k].booking_code_data.name,
          billable: entries[k].booking_code_data.billable ? "Yes" : "No",
          task: entries[k].task ? entries[k].task : "",
          engagementRole: entries[k].engagement_role_name
            ? entries[k].engagement_role_name
            : "",
          leaveCategoryCode: entries[k].booking_code_data.leave_category_code,
          leaveCategoryName: entries[k].booking_code_data.leave_category_name,
          mon: entries[k].minutes_mon,
          tue: entries[k].minutes_tue,
          wed: entries[k].minutes_wed,
          thu: entries[k].minutes_thu,
          fri: entries[k].minutes_fri,
          sat: entries[k].minutes_sat,
          sun: entries[k].minutes_sun,
          total: entries[k].total_minutes,
          comments: entries[k].comments ? entries[k].comments : "",
          timesheet: entries[k].timesheet,
        });
      }
    }

    // Protect entries data sheet
    entriesSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Save file
    try {
      await timesheetWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, `${activeOrganisation.name} - Timesheets.xlsx`);
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Timesheets downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the timesheets file. Please refresh the page and try again.",
      });
    }
  }, [timesheetDownload.timesheets, activeOrganisation.name]);

  useEffect(() => {
    if (timesheetDownload.success) {
      downloadTimesheets();

      dispatch(resetDownloadTimesheetsAction());
    }
    // eslint-disable-next-line
  }, [timesheetDownload.success]);

  // JSX UI code
  return (
    <div>
      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Staff"
      />

      {/* Error alerts */}
      {/* Locations loading error */}
      {locationList.error && (
        <ErrorAlert message="We couldn't load the location list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Roles loading error */}
      {roleList.error && (
        <ErrorAlert message="We couldn't load the role list. Try refreshing the page." />
      )}
      {/* Staff loading error */}
      {staffList.error && (
        <ErrorAlert message="We couldn't load the staff list. Try refreshing the page." />
      )}
      {/* Timesheets loading error */}
      {timesheetList.error && (
        <ErrorAlert message="We couldn't load the timesheet list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Staff"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Data cards */}
      <div style={{ margin: "0 auto -30px auto", maxWidth: "1600px" }}>
        <DataCardContainer
          children={
            <>
              {/* Staff bookings */}
              <StaffBookingsCard2
                bookingProfile={staffList.bookingProfile}
                setStaffQuery={setStaffQuery}
              />
            </>
          }
          fill
        />
      </div>

      {/* Timesheets download */}
      <div
        style={{
          margin: "30px auto -20px auto",
          maxWidth: "1600px",
        }}
      >
        <div
          style={{
            background: lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5,
            display: "flex",
            margin: "0 20px 0 20px",
            padding: "10px 15px 10px 15px",
          }}
        >
          <Button
            icon="download"
            intent="primary"
            loading={timesheetDownload.loading || timesheetList.loading}
            onClick={() => {
              dispatch(
                downloadTimesheetsAction(
                  activeOrganisation.id,
                  timesheetList.count
                )
              );
            }}
            style={{ marginRight: "5px" }}
            text="Timesheets"
          />

          <InfoPopup content="Download timesheet data covering the previous 26 weeks." />
        </div>
      </div>

      {/* Data table */}
      <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
        <TableContainer2
          body={staffList.staff.map((staff) => {
            return (
              <tr
                key={staff.id}
                onClick={() => {
                  if (staff.user_has_permission) {
                    dispatch(resetListStaffDetailsAction());

                    navigate(`/staff/${staff.id}`);
                  } else {
                    AppToaster.show({
                      icon: "warning-sign",
                      intent: "danger",
                      message: "You do not have access to this staff record.",
                    });
                  }
                }}
              >
                {/* Staff */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    image={staff.profile_photo}
                    showImage
                    tagIntent={
                      !staff.user_has_permission
                        ? "danger"
                        : staff.user_access_status === "view"
                        ? "primary"
                        : undefined
                    }
                    tagText={
                      !staff.user_has_permission
                        ? "No access"
                        : staff.user_access_status === "view"
                        ? "View only"
                        : undefined
                    }
                    text={staff.name}
                  />
                </td>

                {/* Role */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    text={staff.role_data ? staff.role_data.name : undefined}
                    url={staff.role ? `/roles/${staff.role}` : undefined}
                  />
                </td>

                {/* Location */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText text={staff.location_name} />
                </td>

                {/* Employment */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText text={staff.employment} />
                </td>

                {/* Manager */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    email={staff.manager_name}
                    image={staff.manager_profile_photo}
                    showImage
                  />
                </td>

                {/* Utilisation */}
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {staff.utilisation_score !== null ? (
                    <Tag
                      intent={
                        staff.utilisation_score >= 80
                          ? "success"
                          : staff.utilisation_score >= 60
                          ? "warning"
                          : "danger"
                      }
                    ></Tag>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            );
          })}
          clearFiltersDisabled={
            !staffQuery.employment &&
            !staffQuery.location &&
            !staffQuery.manager &&
            !staffQuery.role &&
            !staffQuery.searchString &&
            !staffQuery.utilisationScore
          }
          count={staffList.count}
          head={
            <tr>
              {/* Staff */}
              <TableColumnHeadSearch
                queryObject={staffQuery}
                queryVariable="searchString"
                setQueryObject={setStaffQuery}
                setQueryVariable="searchString"
                title="Staff"
                width="200px"
              />

              {/* Role */}
              <TableColumnHeadSelect2
                filterObject={staffQuery}
                filterVariable="role"
                items={roleList.roles}
                setFilterObject={setStaffQuery}
                setQueryObject={setRoleQuery}
                setQueryVariable="searchString"
                title="Role"
                width="200px"
              />

              {/* Location */}
              <TableColumnHeadSelect2
                filterObject={staffQuery}
                filterVariable="location"
                items={locationList.locations}
                setFilterObject={setStaffQuery}
                setQueryObject={setLocationQuery}
                setQueryVariable="searchString"
                title="Location"
                width="200px"
              />

              {/* Employment */}
              <TableColumnHeadSelectSimple
                filterObject={staffQuery}
                filterVariable="employment"
                options={[
                  { display: "All", icon: "filter-remove", value: "" },
                  {
                    display: "Permanent",
                    icon: "full-circle",
                    value: "Permanent",
                  },
                  {
                    display: "Contract",
                    icon: "ring",
                    value: "Contract",
                  },
                  {
                    display: "Casual",
                    icon: "circle",
                    value: "Casual",
                  },
                ]}
                setFilterObject={setStaffQuery}
                title="Employment"
                width="125px"
              />

              {/* Manager */}
              <TableColumnHeadSelect2
                filterObject={staffQuery}
                filterVariable="manager"
                items={managerList.managers}
                setFilterObject={setStaffQuery}
                setQueryObject={setManagerQuery}
                setQueryVariable="searchString"
                title="Manager"
                width="200px"
              />

              {/* Utilisation */}
              <TableColumnHeadSelectSimple
                filterObject={staffQuery}
                filterVariable="utilisationScore"
                options={[
                  { display: "All", icon: "filter-remove", value: "" },
                  {
                    display: "Healthy",
                    icon: "tick-circle",
                    value: "Healthy",
                  },
                  {
                    display: "Needs attention",
                    icon: "warning-sign",
                    value: "Needs attention",
                  },
                  {
                    display: "Needs urgent attention",
                    icon: "error",
                    value: "Needs urgent attention",
                  },
                ]}
                setFilterObject={setStaffQuery}
                title="Utilisation"
                width="75px"
              />
            </tr>
          }
          interactive
          loading={staffList.loading}
          onClearFilters={() => {
            setStaffQuery({
              ...staffQueryDefault,
            });
          }}
          page={staffQuery.page}
          setPageObject={setStaffQuery}
          setPageVariable="page"
        />
      </div>
    </div>
  );
}

export default StaffListScreen;
